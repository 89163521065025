<template>
  <Container v-if="page == '1'" />
  <ContainerTwo v-if="page == '2'" />
  <ContainerThree v-if="page == '3'" />
</template>

<script>
import Container from "./components/Container.vue";
import ContainerTwo from "./components/ContainerTwo.vue";
import ContainerThree from "./components/ContainerThree.vue";

export default {
  name: "App",
  components: {
    Container,
    ContainerTwo,
    ContainerThree,
  },
  data() {
    return {
      page: "1",
    };
  },
  mounted() {
    let params = new URLSearchParams(window.location.search);
    let page = params.get("p");
    if (page) {
      this.page = page;
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
