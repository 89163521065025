<template>
  <div class="container">
    <div class="top-title">基层党建</div>
    <div class="c-center">
      <p class="title">党务展示</p>
      <div class="img-box">
        <img src="../assets/imgs/100.jpg" alt="" style="object-fit: contain" />
      </div>
      <p class="title">三会一课</p>
      <div class="box-one box">
        <div class="img-one">
          <img src="../assets/imgs/dz1.png" alt="" />
        </div>
        <div class="content-box-1">
          <li class="text-t1 t-active">党员大会</li>
          <li class="text-t1">支委会</li>
          <li class="text-t1">党课</li>
          <li class="text-t1">组织生活会</li>
          <div class="qr-code">
            <img src="../assets/imgs/qrcode.png" alt="" />
            <p class="desc">扫码编辑</p>
          </div>
        </div>
      </div>
      <p class="title">主题党日</p>
      <div class="box box-two">
        <div class="img-one">
          <img src="../assets/imgs/dz5.png" alt="" />
        </div>
        <div class="content-box-1">
          <p class="learn-more">查看更多</p>
          <p class="red-text">
            <img
              style="object-fit: contain"
              src="../assets/imgs/title.png"
              alt=""
            />标题：
          </p>
          <p class="text-con">2024年4月主题党日党员讲党课</p>
          <p class="red-text">
            <img
              style="object-fit: contain"
              src="../assets/imgs/title.png"
              alt=""
            />召开时间：
          </p>
          <p class="text-con">2024-04</p>
          <p class="red-text">
            <img
              style="object-fit: contain"
              src="../assets/imgs/title.png"
              alt=""
            />召开地点：
          </p>
          <p class="text-con">央广视讯6层会议室</p>
        </div>
      </div>
    </div>
    <!-- <el-dialog class="show-dialog" align-center v-model="boxVisible">
    </el-dialog> -->
  </div>
</template>
<script>
export default {
  name: "c-ontainer",
  data() {
    return {
      boxVisible: false,
    };
  },
  methods: {
    boxClick() {
      this.boxVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.img-box {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.top-title {
  text-align: center;
  font-size: 30px;
  line-height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #d9080a;
  font-weight: bold;
  color: #fff;
}
.c-center {
  margin-top: 80px;
  padding: 10px 20px;
  color: #000;
  pre {
    font-size: 16px;
    line-height: 30px;
    white-space: pre-wrap;
  }
  .level1,
  .level2,
  .level3 {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .level2 {
    margin-top: 50px;
    position: relative;
  }
  .level3 {
    margin-top: 30px;
  }

  .info {
    position: relative;
    width: 100px;
    .img {
      width: 100px;
      height: 150px;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 14px;
      text-align: center;
    }
    .name {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .t-line-top ::before {
    // 在正上方居中加一条20px的竖线
    content: "";
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 20px;
    background-color: #000;
  }
  .t-line-bottom ::before {
    // 在正下方居中加一条20px的竖线
    content: "";
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 20px;
    background-color: #000;
  }
  .t-row-line::after {
    content: "";
    position: absolute;
    top: -25px;
    width: 66.66%;
    height: 1px;
    background-color: #000;
  }
}
.title {
  background-image: url("../assets/imgs/title-bg.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding-left: 45px;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 20px;
}
.body-container {
  padding-top: 5vw;
  padding-left: 17vw;
  width: 45vw;
  p {
    font-size: 14px;
    line-height: 20px;
    color: red;
    span {
      color: #000;
      font-size: 12px;
    }
  }
}
.box {
  display: flex;
  margin-bottom: 30px;
  .img-one {
    width: 50%;
    img {
      width: 100%;
    }
  }
  .content-box-1 {
    padding-left: 80px;
    flex: 1;
    .text-t1 {
      font-size: 25px;
      line-height: 50px;
      color: #999;
    }
    .qr-code {
      position: relative;
      margin-top: 30px;
      margin-left: 30px;
      width: 40%;
      img {
        width: 100%;
      }
      .desc {
        position: absolute;
        bottom: -25px;
        width: 70px;
        font-size: 16px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .t-active {
      color: #333;
    }
  }
}
.learn-more {
  float: right;
  color: #d81e06;
  margin-bottom: 20px;
}
.red-text {
  clear: both;
  display: flex;
  color: #d81e06;
  font-size: 25px;
  line-height: 50px;
  margin-top: 20px;
  img {
    width: 25px;
    margin-right: 10px;
  }
}
.text-con {
  font-size: 20px;
}
</style>
<style lang="scss">
.show-dialog {
  width: 90vw !important;
  height: 53vw;
  background-image: url("../assets/imgs/dialog-bg.png") !important;
  background-size: 101% auto !important;
  background-repeat: no-repeat !important;
  background-position-x: -2px !important;
}
</style>
