<template>
  <div class="container">
    <div class="top-title">组织机构基本情况</div>
    <div class="c-center">
      <p class="title">组织基本情况</p>
      <pre>
    某某党支部共有党员20名，其中男性党员12名（包含1名少数民族），女性党员8名（包含少数民族1人），入党积极分子1名。
    支部党员有党员领导干部6人，具有研究生学历5人，本科学历的10人，专科学历的4人，中专学历的1人。年龄分部在25-45周岁中间的有13人，45-65周岁之间的有5人，65周岁以上的有2人。
    支部委员会由党支部数据、支部副书记、党建组织员、组织委员、宣传委员、纪检委员6人组成。
    某某党支部结合两学一做，把党的十九大、二十大全会精神贯穿于全体党员工作的始终：努力建设一支充满朝气、求真务实、奋发有为、勇于创新的班子队伍；
    建设一支党性强、奉献精神强、大力探索新模式、新方法、成为改革和发展的先锋党员队伍。
      </pre>
      <p class="title">党组织成员情况</p>
      <div class="level1">
        <div class="info t-line-bottom" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/670acf46f95752d32857c6d3a4905fed.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党支部书记</p>
        </div>
      </div>
      <div class="level2 t-row-line">
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/0547f437266468e390951ba7c68d2f96.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党支部副书记</p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/055647ecb2f886e560947430708c57c7.jpg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党支部副书记</p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党支部副书记</p>
        </div>
      </div>
      <div class="level3">
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党员</p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
      </div>
      <div class="level3">
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党员</p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
      </div>
      <div class="level3">
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党员</p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
      </div>
      <div class="level3">
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job">党员</p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
        <div class="info t-line-top" @click="boxClick">
          <div class="img">
            <img
              src="../assets/imgs/1b22b45c1155a4c0eb67cf47e5d85d0f.jpeg"
              alt=""
            />
          </div>
          <p class="name">张三</p>
          <p class="job"></p>
        </div>
      </div>
    </div>
    <el-dialog class="show-dialog" align-center v-model="boxVisible">
      <div class="body-container">
        <p>姓名：<span>张三</span></p>
        <p>民族：<span>汉族</span></p>
        <p>职务：<span>党支部书记</span></p>
        <p>籍贯：<span>福建莆田</span></p>
        <p>个人简介：</p>
        <p>
          <span
            >1953年9月生，1970年12月参加工作，1978年11月加入中国共产党，清华大学化学与化学工程系催化动力学专业毕业，研究生学历，工学硕士学位，研究员，现任**村党支部书记。</span
          >
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "c-ontainer",
  data() {
    return {
      boxVisible: false,
    };
  },
  methods: {
    boxClick() {
      this.boxVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.top-title {
  text-align: center;
  font-size: 30px;
  line-height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #d9080a;
  font-weight: bold;
  color: #fff;
}
.c-center {
  padding: 10px 20px;
  margin-top: 80px;
  color: #000;
  pre {
    font-size: 16px;
    line-height: 30px;
    white-space: pre-wrap;
  }
  .level1,
  .level2,
  .level3 {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .level2 {
    margin-top: 50px;
    position: relative;
  }
  .level3 {
    margin-top: 30px;
  }

  .info {
    position: relative;
    width: 100px;
    .img {
      width: 100px;
      height: 150px;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 14px;
      text-align: center;
    }
    .name {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .t-line-top ::before {
    // 在正上方居中加一条20px的竖线
    content: "";
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 20px;
    background-color: #000;
  }
  .t-line-bottom ::before {
    // 在正下方居中加一条20px的竖线
    content: "";
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 20px;
    background-color: #000;
  }
  .t-row-line::after {
    content: "";
    position: absolute;
    top: -25px;
    width: 66.66%;
    height: 1px;
    background-color: #000;
  }
}
.title {
  background-image: url("../assets/imgs/title-bg.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding-left: 45px;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 20px;
}
.body-container {
  padding-top: 5vw;
  padding-left: 17vw;
  width: 45vw;
  p {
    font-size: 14px;
    line-height: 20px;
    color: red;
    span {
      color: #000;
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss">
.show-dialog {
  width: 90vw !important;
  height: 53vw;
  background-image: url("../assets/imgs/dialog-bg.png") !important;
  background-size: 101% auto !important;
  background-repeat: no-repeat !important;
  background-position-x: -2px !important;
}
</style>
