<template>
  <div class="container">
    <div class="top-title">党务公开</div>
    <div class="img-box">
      <img src="../assets/imgs/dj1.jpg" alt="" style="object-fit: contain" />
    </div>
    <div class="c-center">
      <!-- 团费 -->
      <div class="expense">
        <div class="left">
          <p>应缴人数：16</p>
          <p>实缴人数：12</p>
          <p class="red-text">缴费列表</p>
        </div>
        <div class="center">
          <img
            src="../assets/imgs/chart.png"
            alt=""
            style="object-fit: contain; width: 65%"
          />
        </div>
        <div class="right">
          <div>
            <p>已缴纳</p>
            <div class="paragraph-line act"></div>
          </div>
          <div>
            <p>未缴纳</p>
            <div class="paragraph-line"></div>
          </div>
        </div>
      </div>
      <p class="title">公示公开</p>
      <h3>
        总结推广浙江“千万工程”经验
        推动学习贯彻习近平新时代中国特色社会主义思想走深走实
      </h3>
      <div class="img-list">
        <div v-for="i in 15" :key="i">
          <img src="../assets/imgs/dz1.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "c-ontainer",
  data() {
    return {
      boxVisible: false,
    };
  },
  methods: {
    boxClick() {
      this.boxVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.img-box {
  margin-top: 61px;
  width: 100%;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
.top-title {
  text-align: center;
  font-size: 30px;
  line-height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #d9080a;
  font-weight: bold;
  color: #fff;
}
.c-center {
  margin-top: 20px;
  padding: 10px 20px;
  color: #000;
  pre {
    font-size: 16px;
    line-height: 30px;
    white-space: pre-wrap;
  }
  .level1,
  .level2,
  .level3 {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .level2 {
    margin-top: 50px;
    position: relative;
  }
  .level3 {
    margin-top: 30px;
  }

  .info {
    position: relative;
    width: 100px;
    .img {
      width: 100px;
      height: 150px;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 14px;
      text-align: center;
    }
    .name {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .t-line-top ::before {
    // 在正上方居中加一条20px的竖线
    content: "";
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 20px;
    background-color: #000;
  }
  .t-line-bottom ::before {
    // 在正下方居中加一条20px的竖线
    content: "";
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 20px;
    background-color: #000;
  }
  .t-row-line::after {
    content: "";
    position: absolute;
    top: -25px;
    width: 66.66%;
    height: 1px;
    background-color: #000;
  }
}
.title {
  background-image: url("../assets/imgs/title-bg.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding-left: 45px;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 20px;
}
.body-container {
  padding-top: 5vw;
  padding-left: 17vw;
  width: 45vw;
  p {
    font-size: 14px;
    line-height: 20px;
    color: red;
    span {
      color: #000;
      font-size: 12px;
    }
  }
}
.box {
  display: flex;
  margin-bottom: 30px;
  .img-one {
    width: 50%;
    img {
      width: 100%;
    }
  }
  .content-box-1 {
    padding-left: 80px;
    flex: 1;
    .text-t1 {
      font-size: 25px;
      line-height: 50px;
      color: #999;
    }
    .qr-code {
      position: relative;
      margin-top: 30px;
      margin-left: 30px;
      width: 40%;
      img {
        width: 100%;
      }
      .desc {
        position: absolute;
        bottom: -25px;
        width: 70px;
        font-size: 16px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .t-active {
      color: #333;
    }
  }
}
.learn-more {
  float: right;
  color: #d81e06;
  margin-bottom: 20px;
}
.red-text {
  clear: both;
  display: flex;
  color: #d81e06;
  font-size: 25px;
  line-height: 50px;
  margin-top: 20px;
  img {
    width: 25px;
    margin-right: 10px;
  }
}
.text-con {
  font-size: 20px;
}
.expense {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  .left {
    width: 30%;
    p {
      font-size: 16px;
      line-height: 30px;
    }
    .red-text {
      color: #d81e06;
      margin-top: 40px;
    }
  }
  .center {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    padding-top: 90px;
    width: 30%;
    div {
      float: right;
      clear: both;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666;
      .paragraph-line {
        width: 50px;
        margin-left: 10px;
        height: 10px;
        background-color: #d4abab;
      }
      .act {
        background-color: #d81e06;
      }
    }
  }
}
.img-list {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  div {
    width: 30%;
    margin-bottom: 20px;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.show-dialog {
  width: 90vw !important;
  height: 53vw;
  background-image: url("../assets/imgs/dialog-bg.png") !important;
  background-size: 101% auto !important;
  background-repeat: no-repeat !important;
  background-position-x: -2px !important;
}
</style>
